import ApiBase from '@/api/base'
import {notify} from "@/helpers/notifyHelper"

class Founders {
	static create (self, data) {
		return self.http('post', `${ApiBase.baseUrl()}/team/create`, data)
			.then(response => {
				notify(self, 'success', response.data.message)
			})
	}

	static getById (self, id) {
		return self.http('get', `${ApiBase.baseUrl()}/team/${id}`)
	}

	static update (self, id, data) {
		return self.http('put', `${ApiBase.baseUrl()}/team/${id}`, data)
			.then(response => {
				notify(self, 'success', response.data.message)
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}

	static getAll (self) {
		this.getPagination(self, 0, 100)
	}

	static getPagination (self, offset, limit) {
		self.http('get', `${ApiBase.baseUrl()}/team/list/offset/${offset}/limit/${limit}`)
			.then(response => {
				ApiBase.setToStore(null, 'setTeamList', response.data)
			})
	}

	static delete (self, id) {
		self.http('delete', `${ApiBase.baseUrl()}/team/delete/${id}`)
			.then(response => {
				notify(self, 'success', response.data.message)
				self.$root.$emit('ConfirmingModal', false)
				this.getAll(self)
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}

	static deleteColumn (self, id, colName) {
		return self.http('put', `${ApiBase.baseUrl()}/team/delete/${id}/column/${colName}`, {})
			.then(response => {
				notify(self, 'success', response.data.message)
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}

}

export default Founders
